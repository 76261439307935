.ssai-enhanced-table tbody tr[style*="background-color"] {
    position: relative;
    background-color: #ffe5ef !important;
}
.ssai-enhanced-table tbody tr[style*="background-color"] > th:first-child::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 8px;
    background-color: #ff0e4f;
    top: 0px;
    left: 0px;
}

.ssai-enhanced-table .ssai-table-switch {
    display: inline-block;
}

.ssai-enhanced-table .ssai-table-switch > span > span > span > span {
    background-color: #979797;
    width: 10px;
    height: 10px;
}

.ssai-enhanced-table .ssai-table-switch > span > span > span > span:has(+ input:checked) {
    background-color: #ff0066;
}

.ssai-enhanced-table .ssai-table-switch > span > span:last-child {
    background-color: transparent;
    border: 2px solid #2a2a2a;
    height: 17px;
    margin-top: -8px;
}

.ssai-enhanced-table .ssai-table-switch > span > span > span:last-child:has(input:disabled) {
    display: flex !important;
}

.ssai-enhanced-table .ssai-table-switch > span > span > span:has(input:disabled) > span {
    background-color: #c3c3c3;
}

.ssai-icon-button {
    color: rgba(0, 0, 0, 0.40) !important;
    background-color: transparent !important;
}

.ssai-icon-button:not(.ssai-button-no-effect):hover {
    color: #ff0e4f !important;
}

.ssai-icon-button:hover > span:last-child {
    display: none;
}