.nemo2-toaster .toaster-item > div {
    margin-top: 20px;
}

.nemo2-toaster .toaster-item.toaster-success > div > div {
    background-color: rgb(56, 142, 60);
}

.nemo2-toaster .toaster-item.toaster-error > div > div {
    background-color: rgb(211, 47, 47)
}

.nemo2-toaster svg {
    width: 22px;
    fill: #fff;
    vertical-align: middle;
    margin-right: 15px;
}