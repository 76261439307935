.map {
  max-width: 100%;
  width: 100%;
  height: auto;
  background-color: white;
}
.country {
  stroke: white;
  stroke-width: 0.5;
  vector-effect: non-scaling-stroke;
}
.button {
  cursor: pointer;
}
